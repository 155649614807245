import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import { useNavigate } from 'react-router-dom'

const SalesmanForm = (props) => {
    const [data, setData] = useState({
        amount: 0,
        code: ""
    });

    const [file, setFile] = useState(null);

    const nav = useNavigate()
    function handleChanges(e) {
        if (e.target.name !== "amount") {
            setData({
                ...data,
                [e.target.name]: e.target.value.trim() ? e.target.value.trim().toUpperCase() : e.target.value.trim()
            })
        } else {
            if (!isNaN(e.target.value)) {
                setData({
                    ...data,
                    [e.target.name]: e.target.value.trim()
                })
            }
        }
    }


    function handleFile(e) {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        const { amount, code } = props
        setData({ amount, code })
    }, [props])

    function handleSubmit() {
        props.onClick(data)
    }

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {props.title} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6'>
                        <Input placeholder='Enter amount' onChange={handleChanges} value={data.amount} name="amount" />
                    </div>
                    <div className='col-md-6'>
                        <Input type="text" placeholder='Enter code' onChange={handleChanges} value={data.code} name="code" />
                    </div>

                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={handleSubmit} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesmanForm