import React, { useEffect, useState } from 'react'
import SalesmanForm from './SalesmanForm'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, postRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate } from 'react-router-dom';

const AddLot = () => {
    const [data, setData] = useState({
        code: ""
    });

    const nav = useNavigate()

    async function addLot(obj) {
        try {
            const { data } = await postRequest(APIS.LOTS, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    return (
        <SalesmanForm title="Add Coupon" code={data.code} onClick={(obj) => addLot(obj)} />
    )
}

export default AddLot