// const BASE_URL = 'http://localhost:6014'
const BASE_URL = process.env.REACT_APP_BASE_URL

const routes = {
    LOGIN: "/admin/login",
    GET_ADMIN_PROFILE: "/admin/my-profile",

    CHANGE_PASSWORD: "/admin/change-password",
    GET_STATS: "/admin/get-stats",
    GET_DATA: "/admin/get-data",
    ALL_PROPERTIES: "/admin/getAllProperty",
    FORGOT_PASSWORD: "/admin/recoverAccount",
    VERIFY_OTP: "/admin/verifyOtp",
    VERIFY_CHANGE_PASSWORD: "/admin/verify-change-password",
    BLOCK_USER: "/admin/blockUser/",
    GET_DOCUMENTS: "/admin/getdocuments",
    UPDATE_PROFILE: "/admin/update-profile",
    UPDATE_SUBADMIN_PROFILE: "/admin/profile/",
    GET_REASONS: "/admin/getReason/",
    HIDE_SHOW_PROPERTY: "/admin/hideProperty/",
    GET_PAYMENTS: "/admin/get-payments",
    ALL_USERS: "/admin/getAllUsers",
    GENERATE_TOKEN: "/admin/generate-user-token/",
    DOWNLOAD_DOCUMENTS: "/property/downloadDocuments/",
    GET_PRIVACY_POLICY: "/admin/get_content",

    //content section
    UPDATE_CONTENT: "/admin/update-content",
    UPDATE_CONTENT_TERMS: "/admin/update_terms_conditions",
    GET_TERMS_AND_CONDITIONS: "/admin/getTerms",
    GET_ALL_FAQ: "/admin/faq_listing",
    GET_SINGLE_FAQ: "/admin/get_single_faq/",
    UPDATE_SINGLE_FAQ: "/admin/update_faq",
    DELETE_FAQ: "/admin/delete_faq/",
    CREATE_FAQ: "/admin/add_faq",


    //dashboard section
    DASHBOARD: "/admin/dashboard",
    //user section
    GET_ALL_USERS: "/admin/users",
    BLOCK_UNBLOCK_USER: "/admin/block-unblock/",


    FINANCE: "/admin/finance",
    CATEGORIES: "/admin/categories",
    CATEGORIES_ITEM: "/admin/categories-items",
    SHOP: "/admin/payments",
    REQUEST: "/admin/request",
    SALESMAN: "/admin/salesman",
    LIST: "/admin/list/",
    USER: "/admin/users",
    EMI: "/admin/emis",
    MONTHS: "/admin/get-months",

    SETTINGS: "/admin/settings",
    MARK_READ: "/admin/mark-read",
    DOWNLOAD: "/admin/download",

    LOTS: "/admin/coupon",
    INVESTMENT: "/admin/investment",
    PAYNOW: "/admin/mark-paid",
}

export default routes;
export { BASE_URL }