import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import { useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'

const ShopForm = (props) => {
    const [link, setLink] = useState("")

    const [shop_image, setShopImage] = useState(null)

    const nav = useNavigate()

    function handleFiles(e) {
        if (e.target.name === "shop_image") {
            setShopImage(e.target.files[0])
        }
    }

    function submitForm() {
        let formData = new FormData();

        if (!shop_image) {
            toaster("Please choose a file first", false)
            return false
        } else if (link === "") {
            toaster("Please enter the coupon link", false)
            return false
        } else {
            formData.append('image', shop_image)
            formData.append('link', link)
            return formData
        }
    }

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {props.title} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6 mb-3'>
                        <Input label="Payment Proof" type="file" onChange={handleFiles} name="shop_image" />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Input label="Coupon Link" placeholder='Enter coupon link' type="text" value={link} onChange={(e) => setLink(e.target.value.trim())} name="link" />
                    </div>

                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={() => props.onClick(submitForm())} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShopForm