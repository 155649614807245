import React, { useEffect, useState } from 'react'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Card from '../../common/Card'

export default function Dashboard() {
    const [data, setData] = useState({});
    const [load, setLoad] = useState(true);
    const nav = useNavigate();

    const fetchData = async () => {
        try {
            const result = await getRequest(APIS.DASHBOARD);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                setData(result.data.data)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="pagetitle">
                Dashboard
            </div>
            <div className="row mt-5">
                <Card Provider={"Coupons"} number={data?.coupons ?? 0} />
                <Card Provider={"Records"} number={data?.records ?? 0} />
                {/* <Card Provider={"Categories"} number={data?.categories ?? 0} />
                <Card Provider={"Products"} number={data?.products ?? 0} /> */}
            </div>
        </>
    )
}
