import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import ImagePopup from '../../common/popups/Image';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import { toaster } from '../../apis/commonFunctions';
import Button from '../../common/Button';
import Image from '../../common/Image';
import NoDataRow from '../../common/NoDataRow';

const UserDetails = () => {

    const nav = useNavigate()

    const [details, setDetails] = useState({
        interest: []
    });

    const [url, setUrl] = useState("")

    const { id } = useParams();

    async function fetchDetails() {
        try {
            const { data } = await getRequest(APIS.USER + "/" + id);
            if (data.status) {
                setDetails({ ...details, ...data.data })
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [id]);

    return (
        <>
            <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                User Details
            </div>
            <div className={"row"}>
                <div className="col-lg-2 col-md-2">
                    <div className="profile_image">
                        <Image src={details?.profile_pic !== "" ? process.env.REACT_APP_IMAGE_URL + details?.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                    </div>
                </div>
                <div className="col-lg-10 col-md-10">
                    <ul className="profile_list p-0 row custom-css">
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Firstname</div>
                                <div className="name mt-2">{details?.firstname !== "" ? details?.firstname : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Lastname</div>
                                <div className="name mt-2">{details?.lastname !== "" ? details?.lastname : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Phone</div>
                                <div className="name mt-2">{details?.mobile && details?.mobile !== "" ? details?.mobile : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Email</div>
                                <div className="name mt-2 text-lowercase">{details?.email && details?.email !== "" ? details?.email : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">City</div>
                                <div className="name mt-2 text-lowercase">{details?.city && details?.city !== "" ? details?.city : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">State</div>
                                <div className="name mt-2 text-lowercase">{details?.state && details?.state !== "" ? details?.state : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Pincode</div>
                                <div className="name mt-2">{details?.pincode && details?.pincode !== "" ? details?.pincode : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Verification Status</div>
                                <div className="name mt-2">{details?.mobile_verified ? "Verified" : "Not Verified"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Coupon Code</div>
                                <div className="name mt-2">{details?.code && details?.code !== "" ? details?.code : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">UPI</div>
                                <div style={{textTransform : "unset"}} className="name mt-2">{details?.upi && details?.upi !== "" ? details?.upi : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Payment Proof</div>
                                <div className="name mt-2">{details.proof !== "" ? <a href={process.env.REACT_APP_IMAGE_URL + details.proof} target='_blank'>View</a> : "Not added"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Coupon Amount</div>
                                <div className="name mt-2">{details?.amount !== 0 ? details.amount : "N/A"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Coupon Link</div>
                                <div className="name mt-2">{details?.link !== "" ? <a href={details?.link} target='_blank'>Open</a> : "Not added"}</div>
                            </div>
                        </li></div>

                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Interests</div>
                                <div className="name mt-2">{details?.interest !== "" ? details?.interest.join(" & ") : "Not added"}</div>
                            </div>
                        </li></div>

                    </ul>
                </div>
            </div>
            <ImagePopup src={url} />
        </>
    )
}

export default UserDetails