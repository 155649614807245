import React, { useEffect, useState } from 'react'
import { Block, Delete, Eye, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import PropertyCard from '../../common/PropertyCard'
import Search_input from '../../common/Search_input'



export default function ShopManagement() {
    const [all, setAll] = useState([]);
    const nav = useNavigate()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100);
    const [search, setSearch] = useState("");
    const [mainid, setMainId] = useState("");

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.SHOP}?search=${search}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const deleteShop = async (id) => {
        try {
            const { data } = await deleteRequest(`${APIS.SHOP}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const [key,setKey] = useState('')

    useEffect(() => {
        fetchData();
    }, [search])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Payments Management <br />
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    {/* <Button text="Add Shop" className="btn btn-success" onClick={() => nav("add")} /> */}
                    {/* <Search_input  placeholder={'Search here'} search={key}  onChange={(e)=>setKey(e.target.value)} icon={<Search />} className='search_input mt-3' /> */}
                </div>
            </div>
            <div className="row mt-5">
                <table className='table table-lg'>
                    <thead className='table-light'>
                        <tr colSpan="4">
                            <th>S.No</th>
                            <th>Code</th>
                            <th>Amount</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>City</th>
                            <th>State</th>
                            <th>UPI</th>
                            <th>Link</th>
                            <th>Proof</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {all.length > 0
                            ?
                            all.map((element, index) => (
                                <tr key={element._id}>
                                    <td>{index + 1}</td>
                                    <td>{element.code}</td>
                                    <td>{element.amount}</td>
                                    <td>{element.firstname + " " + element.lastname}</td>
                                    <td>{element.mobile}</td>
                                    <td style={{textTransform : "none"}}>{element.email}</td>
                                    <td>{element.city}</td>
                                    <td>{element.state}</td>
                                    <td style={{textTransform : "none"}}>{element.upi}</td>
                                    <td>{element.link !== "" ? <a href={element.link} target='_blank'>Open</a> : "Not added"}</td>
                                    <td>{element.proof !== "" ? <a href={process.env.REACT_APP_IMAGE_URL + element.proof} target='_blank'>View</a> : "Not added"}</td>
                                    {/* <td><Switch onChange={(e) => { toggleSwitch({ ...element, status: e }) }} checked={element.status} />
                                        </td> */}
                                    <td>
                                        <div className="actions">
                                            <button className='block' onClick={() => nav(element._id)} >
                                                <Pencil />
                                            </button>
                                            <Button className="btn btn-danger table-btn" data-bs-toggle="modal" data-bs-target="#decline_modal" onClick={() => setMainId(element._id)} text={<Delete />} style={{ color: "red", fontWeight: "100", padding: "5px 5px" }} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                            : <NoDataRow />}
                    </tbody>
                </table>
                {/* {all.map(item => <div className='col-md-4 cursor-pointer'>
                    <PropertyCard onDelete={() => setMainId(item._id)} id={item._id} owner={item.owner_name} title={item.shop_name} address={item.shop_address} image={item.shop_image} />
                </div>)} */}
            </div>
            <ConfirmationPopup message={"Do you really want to delete this shop ?"} onYes={() => deleteShop()} />
        </>
    )
}