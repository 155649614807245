import React, { useEffect, useState } from 'react'
import { Block, Delete, Eye, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'



export default function LotsManagement() {
    const [all, setAll] = useState([]);
    const nav = useNavigate()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100);
    const [search, setSearch] = useState("");
    const [mainid, setMainId] = useState("");

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.LOTS}?search=${search}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const deleteLot = async () => {
        try {
            const { data } = await deleteRequest(`${APIS.LOTS}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [search])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Coupons Management <br />
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <Button text="Add Coupon" className="btn btn-success" onClick={() => nav("add")} />
                    {/* <Search_input  placeholder={'Search here'} search={key}  onChange={(e)=>setKey(e.target.value)} icon={<Search />} className='search_input mt-3' /> */}
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colSpan="4">
                                <th>S.No</th>
                                <th>Amount</th>
                                <th>Code</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {all.length > 0
                                ?
                                all.map((element, index) => (
                                    <tr key={element._id}>
                                        <td>{index + 1}</td>
                                        <td style={{ textTransform: "inherit" }}>{element.amount}</td>
                                        <td style={{ textTransform: "unset" }}>{element.code}</td>
                                        <td>
                                            <div className="actions">
                                                <button className='view p-2'>
                                                    <Link className='view' to={element._id}>
                                                        <Pencil />
                                                    </Link>
                                                </button>
                                                {/* <button className='block' onClick={() => { blockUnblockUser(element._id) }} style={{ color: (element.admin_blocked ? "#0F993E" : "#ff4c27") }}>
                                                    <Block />
                                                </button> */}
                                                <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                    <Delete />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : <NoDataRow />}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmationPopup message={"Do you really want to delete this lot ?"} onYes={() => deleteLot()} />
        </>
    )
}
