import './App.css';
import './Mobile.css'
import { Route, Routes, useNavigate } from 'react-router-dom';
import Protected from './guards/Protected';
import Authentic from './guards/Authentic';
import Login_Signup from './components/admin/Login_Signup';
import ForgotPassword from './components/admin/ForgotPassword';
import Dashboard from './components/home/Dashboard';
import PrivacyPolicy from './components/content/PrivacyPolicy';
import TermsCondition from './components/content/TermsCondition';
import AdminProfile from './components/admin/AdminProfile';
import ChangePassword from './components/admin/ChangePassword';
import PageNotFound from './common/PageNotFound';
import { useDispatch } from 'react-redux';
import { getRequest, setRouter } from './apis/functions';
import APIS from './apis/routes';
import { useEffect } from 'react';
import { setDetails } from './redux/slices/detailsSlice';
import { toaster } from './apis/commonFunctions';
import UserManagement from './components/users/UserManagement';
import UserDetails from './components/users/UserDetails';
import Settings from './components/admin/Settings';
import InvestorManagement from './components/investors/InvestorManagement';
import InvestorDetails from './components/investors/InvestorDetails';
import LotsManagement from './components/lots/LotsManagement';
import AddLot from './components/lots/AddLot';
import UpdateLot from './components/lots/UpdateLot';
import InvestmentManagement from './components/investment/InvestmentManagement';
import InvestmentDetails from './components/investment/InvestmentDetails';
import CategoryManagement from './components/categories/CategoryManagement';
import AddCategory from './components/categories/AddCategory';
import UpdateCategory from './components/categories/UpdateCategory';
import CategoryItemManagement from './components/categories/items/CategoryItemManagement';
import AddCategoryItem from './components/categories/items/AddCategoryItem';
import UpdateCategoryItem from './components/categories/items/UpdateCategoryItem';
import ShopManagement from './components/shop/ShopManagement';
import UpdateShop from './components/shop/UpdateShop';


function App() {
  const router = useNavigate();
  const dispatcher = useDispatch()
  setRouter(router);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest(APIS.GET_ADMIN_PROFILE);
        let info = JSON.parse(localStorage.getItem('info'))
        info.read = data.data.read
        localStorage.setItem('info', JSON.stringify(info))
        if (data.status) {
          dispatcher(setDetails(data.data))
        }
        router("/login")
      } catch (err) {
        // toaster(err.message, false);
      }
    })()
    // generatePDF()
  }, [])

  return (
    <>
      {/* <Login_Signup/> */}
      <Routes>
        <Route path='/login' element={<Authentic><Login_Signup /></Authentic>} />
        <Route path='/forgot-password' element={<Authentic><ForgotPassword /></Authentic>} />
        <Route path='/' element={<Protected />}>
          <Route exact path='/' element={<Dashboard />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-and-condition' element={<TermsCondition />} />

          

          <Route exact path='/coupon-management' element={<LotsManagement />} />
          <Route exact path='/coupon-management/:id' element={<UpdateLot />} />
          <Route exact path='/coupon-management/add' element={<AddLot />} />

          <Route exact path='/payment-proof-management' element={<ShopManagement />} />
          <Route exact path='/payment-proof-management/:id' element={<UpdateShop />} />
          {/* <Route exact path='/payment-proof-management/add' element={<AddLot />} /> */}

          <Route exact path='/user-management' element={<UserManagement />} />
          {/* <Route exact path='/user-management/add' element={<AddSalesman />} /> */}
          <Route exact path='/user-management/:id' element={<UserDetails />} />

          <Route exact path='/admin/profile' element={<AdminProfile />} />
          <Route exact path='/admin/change-password' element={<ChangePassword />} />
          <Route exact path='/settings' element={<Settings />} />
          {/* <Route exact path='/provider-management' element={<ProviderManagement />} /> */}
        </Route>
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;