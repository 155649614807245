import React from 'react'
import { Annoucement, Disclaimer, Home, Payment, Service, User, Star } from './Icons'
import { NavLink } from 'react-router-dom';

export default function Sidebar() {

    return (
        <>
            <aside id='sidebar' className='sidebar'>
                <ul className='sidenav' id='sidenav'>

                    {/* Dashboard */}
                    <div className="menu_title">Dashboard</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/" className="nav-link">
                            <span><Home /></span>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>

                    <div className="menu_title">Management</div>

                    {/* <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/shop-management" className="nav-link">
                            <span><Home /></span>
                            <span>Shops</span>
                        </NavLink>
                    </li> */}

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/coupon-management" className="nav-link">
                            <span><User /></span>
                            <span>Coupons</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/payment-proof-management" className="nav-link">
                            <span><Annoucement /></span>
                            <span>Payment Requests</span>
                        </NavLink>
                    </li>

                     <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/user-management" className="nav-link">
                            <span><User /></span>
                            <span>Users</span>
                        </NavLink>
                    </li>
{/*
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/request-management" className="nav-link">
                            <span><Annoucement /></span>
                            <span>Requests</span>
                        </NavLink>
                    </li> */}

                    {/* <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/emi-management" className="nav-link">
                            <span><Annoucement /></span>
                            <span>Upcoming EMIs</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/settings" className="nav-link">
                            <span><Annoucement /></span>
                            <span>Settings</span>
                        </NavLink>
                    </li> */}

                    {/* Content Management */}
                    {/* {((info.role === "SUPERADMIN") || info.privillages.includes("CONTENTMANAGEMENT")) && <> */}
                    {/* <div className="menu_title">Content Management</div> */}
                    {/* <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/faq" className="nav-link">
                            <span><Service /></span>
                            <span>Faqs</span>
                        </NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/privacy-policy" className="nav-link">
                            <span><Service /></span>
                            <span>Privacy Policy</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/terms-and-condition" className="nav-link">
                            <span><Service /></span>
                            <span>Terms & conditions</span>
                        </NavLink>
                    </li> */}
                </ul>
            </aside>
        </>
    )
}


