import React, { useEffect, useState } from 'react'
import ShopForm from './ShopForm'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate, useParams } from 'react-router-dom';

const UpdateShop = () => {
    const [data, setData] = useState({
        link : ""
    });

    const { id } = useParams();

    const nav = useNavigate()

    async function updateFinance(obj) {
        if (obj) {
            try {
                const { data } = await patchRequest(APIS.SHOP + "/" + id, obj);
                if (data.status) {
                    toaster(data.message, true);
                    nav(-1)
                } else {
                    toaster(data.message, false);
                }
            } catch (err) {
                toaster(err, false)
            }
        }
    }

    async function fetchFinance() {
        try {
            const { data } = await getRequest(APIS.SHOP + "/" + id);
            if (data.status) {
                setData(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchFinance()
    }, [id]);

    return (
        <ShopForm title="Upload Payment Proof" onClick={(obj) => updateFinance(obj)} />
    )
}

export default UpdateShop