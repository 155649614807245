import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { patchRequest, setToken } from '../apis/functions';
import API from '../apis/routes';
import { useDispatch, useSelector } from 'react-redux'
import { toaster } from '../apis/commonFunctions';
import { markReadSlice } from '../redux/slices/detailsSlice';

export default function Profile_dropdown() {
    const router = useNavigate();
    const logout = () => {
        localStorage.clear();
        setToken("")
        setTimeout(() => {
            router('/login')
        })
    }

    const dispatcher = useDispatch();

    async function markRead() {
        try {
            await patchRequest(API.MARK_READ,{});
            dispatcher(markReadSlice({read : true}));
        } catch (err) {
            toaster(err.message, false);
        }
    }

    async function clickHandler() {
        markRead();
        router("/request-management");
    }

    const info = useSelector(state => state.details)

    return (
        <div className="profile">
            <div className="dropdown">
                {info.profile_pic !== "" ?
                    <img src={process.env.REACT_APP_IMAGE_URL + info.profile_pic} alt="userDefault" className='img-fluid rounded-circle sidebar-profile' />
                    :
                    <img src="/user.jpg" alt="userDefualt" className='img-fluid rounded-circle sidebar-profile' />
                }
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {info.name}
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><Link className="dropdown-item" to="/admin/profile">Profile</Link></li>
                    <li><Link className="dropdown-item" to="/admin/change-password">Change Password</Link></li>
                    <li><Link className="dropdown-item" onClick={logout}>Logout</Link></li>
                </ul>
            </div>
        </div>
    )
}
