import React, { useEffect, useState } from 'react'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate, useParams } from 'react-router-dom';
import SalesmanForm from './SalesmanForm';

const UpdateLot = () => {
    const [data, setData] = useState({
        amount : 0,
        code : ""
    });

    const { id } = useParams();

    const nav = useNavigate()

    async function updateSalesman(obj) {
        try {
            const { data } = await patchRequest(APIS.LOTS + "/" + id, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchLot() {
        try {
            const { data } = await getRequest(APIS.LOTS + "/" + id);
            if (data.status) {
                setData(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchLot()
    }, [id]);

    return (
        <SalesmanForm title="Update Coupon" amount={data.amount} code={data.code} onClick={(obj) => updateSalesman(obj)} />
    )
}

export default UpdateLot